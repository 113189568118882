import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bazaarna-reason-popup',
  templateUrl: './reason-popup.component.html',
  styleUrls: ['./reason-popup.component.scss']
})
export class ReasonPopupComponent {
  constructor(private dialogRef: MatDialogRef<ReasonPopupComponent>) {}
  closeDialog(): void {
    // Write your stuff here
    this.dialogRef.close(); // <- Closes the dialog
  }
}
