<ng-container *ngIf="dataSource && dataSource?.length; else noData">
  <div class="tables geo_tbl">
    <mat-table #table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="#">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          '#' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          {{ i + 1 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="driverDetail">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'DriverDetails' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <p>{{ element.name }} (
            {{
              element.salaryType === salaryType.SALARIED
                ? 'S'
                : element.salaryType === salaryType.COMMISSIONED
                ? 'C'
                : 'S+C'
            }}
            )</p>
          <p>{{ element.countryCode }}-{{ element?.phone }}</p>
          <p>{{ element.email }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vehicleType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'VehicleType' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.vehiclecategory.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'Action' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button type="button"
            class="btn btn-primary pdng"
            (click)="onDriverSelection(element._id)">
            {{ 'Assign' | translate }}
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</ng-container>

<ng-template #noData>
  <bazaarna-nodatafound></bazaarna-nodatafound>
</ng-template>
