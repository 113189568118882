/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Merchant } from '../models/merchant';
import { MerchantOutlet } from '../models/merchantOutlet';
import { MerchantResponse } from '../models/merchantResponse';
import { Attributes } from '../models/attribute';
import { AttributeResponse } from '../models/attributeResponse';
import { GenderPreference } from '../models/genderPreference';
import { ProductVariant } from '../models/productVariant';
import { Products } from '../models/products';
import { OutletResponse } from '../models/outletResponse';
import { ProductResponse } from '../models/productResponse';
import { ProductStatus } from '../models/productStatus';
import { ExportCsvResponse } from '../core/const/exportCsvResponse';
import { keyValuePair } from '../models/keyValuePair';
import { ShiftData } from '../models/shiftData';
import { UpdateInventoryPayload } from '../models/UpdateInventoryPayload';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  days: Array<keyValuePair> = [
    {
      key: 'Sunday',
      value: 0
    },
    {
      key: 'Monday',
      value: 1
    },
    {
      key: 'Tuesday',
      value: 2
    },
    {
      key: 'Wednesday',
      value: 3
    },
    {
      key: 'Thursday',
      value: 4
    },
    {
      key: 'Friday',
      value: 5
    },
    {
      key: 'Saturday',
      value: 6
    }
  ];

  variationData: ProductVariant = {
    variantName: '',
    variantName_ar: '',
    size: '',
    color: '',
    mrpPrice: null,
    sellingPrice: null,
    totalQuantity: null,
    availableQuantity: null,
    maxPurchaseLimit: null,
    isAvailable: true,
    isVatAvailable: false,
    image: [],
    _id: ''
  };

  constructor(private http: HttpClient) {}

  NumOnly(event: any): void {
    const inputText = event.target.value.replace(/[^0-9.]/g, '');
    const decimalIndex = inputText.indexOf('.');

    if (decimalIndex !== -1) {
      const integerPart = inputText.slice(0, decimalIndex); // Extract the integer part
      const decimalPart = inputText.slice(decimalIndex + 1); // Extract the decimal part
      event.target.value = `${integerPart}.${decimalPart.slice(0, 2)}`; // Limit decimal upto 2 digits
    } else {
      event.target.value = inputText.slice(0, 10);
    }
  }

  preventPaste(event: any): void {
    event.preventDefault();
  }

  getMerchants(queryString: string): Observable<MerchantResponse> {
    return this.http
      .get<{ data: MerchantResponse }>(
        `${environment.base_url}merchant/getMerchant?${queryString}`
      )
      .pipe(map(res => res.data));
  }

  getMerchantById(id: string): Observable<Merchant> {
    return this.http
      .get<{ data: Merchant }>(
        `${environment.base_url}merchant/getMerchantById/${id}`
      )
      .pipe(map(res => res.data));
  }

  addMerchant(payload: Merchant): Observable<Merchant> {
    return this.http
      .post<{ data: Merchant }>(
        `${environment.base_url}/merchant/addMerchant`,
        payload
      )
      .pipe(map(res => res.data));
  }

  updateMerchant(payload: Merchant, id: string): Observable<Merchant> {
    return this.http
      .put<{ data: Merchant }>(
        `${environment.base_url}merchant/updateMerchant/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  deleteMerchant(id: string): Observable<number> {
    return this.http
      .delete<{ statusCode: number }>(
        `${environment.base_url}merchant/deleteMerchant/${id}`
      )
      .pipe(map(res => res.statusCode));
  }

  getMerchantOutlets(query: string): Observable<OutletResponse> {
    return this.http
      .get<{ data: OutletResponse }>(
        `${environment.base_url}merchant/getOutlet?${query}`
      )
      .pipe(map(res => res.data));
  }

  getMerchantOutletsById(id: string): Observable<MerchantOutlet> {
    return this.http
      .get<{ data: MerchantOutlet }>(
        `${environment.base_url}merchant/getOutletById/${id}`
      )
      .pipe(map(res => res.data));
  }

  addMerchantOutlets(payload: MerchantOutlet): Observable<MerchantOutlet> {
    return this.http
      .post<{ data: MerchantOutlet }>(
        `${environment.base_url}merchant/addOutlet`,
        payload
      )
      .pipe(map(res => res.data));
  }

  updateOutlet(
    payload: MerchantOutlet,
    id: string
  ): Observable<MerchantOutlet> {
    return this.http
      .put<{ data: MerchantOutlet }>(
        `${environment.base_url}merchant/updateOutlet/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  deleteOutlet(id: string): Observable<number> {
    return this.http
      .delete<{ statusCode: number }>(
        `${environment.base_url}merchant/deleteOutlet/${id}`
      )
      .pipe(map(res => res.statusCode));
  }

  getAttributes(): Observable<AttributeResponse> {
    return this.http
      .get<{ data: AttributeResponse }>(
        `${environment.base_url}merchant/getAttributeType`
      )
      .pipe(map(res => res.data));
  }

  getAttributeValues(id: string): Observable<Attributes[]> {
    return this.http
      .get<{ data: { attribute: Attributes[] } }>(
        `${environment.base_url}merchant/getAttributeValue/${id}`
      )
      .pipe(map(res => res.data.attribute));
  }

  getProduct(query: string): Observable<ProductResponse> {
    return this.http
      .get<{ data: ProductResponse }>(
        `${environment.base_url}merchant/getProduct?${query}`
      )
      .pipe(map(res => res.data));
  }

  getProductById(id: string): Observable<Products> {
    return this.http
      .get<{ data: Products }>(
        `${environment.base_url}merchant/getProduct/${id}`
      )
      .pipe(map(res => res.data));
  }

  addProduct(payload: Products): Observable<ProductVariant> {
    return this.http
      .post<{ data: ProductVariant }>(
        `${environment.base_url}merchant/addProduct`,
        payload
      )
      .pipe(map(res => res.data));
  }

  updateProduct(payload: Products, id: string): Observable<ProductVariant> {
    return this.http
      .put<{ data: ProductVariant }>(
        `${environment.base_url}merchant/productUpdate/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }
  updateProductStatus(
    payload: ProductStatus,
    id: string
  ): Observable<Products> {
    return this.http
      .put<{ data: Products }>(
        `${environment.base_url}merchant/updateProductStatus/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  deleteProduct(data: { productArr: string[] }): Observable<Products> {
    return this.http
      .post<{ data: Products }>(
        `${environment.base_url}admin/deleteMultipleProducts`,
        data
      )
      .pipe(map(res => res.data));
  }

  getGenderPreference(): Observable<Array<GenderPreference>> {
    return this.http
      .get<{ data: Array<GenderPreference> }>(
        `${environment.base_url}merchant/getLabel`
      )
      .pipe(map(res => res.data));
  }

  getProductInventory(id: string): Observable<any> {
    return this.http
      .get<{ data: any }>(
        `${environment.base_url}merchant/getProductInventory/${id}`
      )
      .pipe(map(res => res.data));
  }

  updateProductInventory(payload: any): Observable<any> {
    return this.http.put(
      `${environment.base_url}merchant/updateProductInventory`,
      payload
    );
  }

  exportCsv(query: string): Observable<ExportCsvResponse> {
    return this.http.get<ExportCsvResponse>(
      `${environment.base_url}merchant/getProductCsvExport?${query}`
    );
  }

  exportOutletCsv(query: string): Observable<ExportCsvResponse> {
    return this.http.get<ExportCsvResponse>(
      `${environment.base_url}merchant/outletCSV?${query}`
    );
  }

  updateShifts(payload: any): Observable<any> {
    return this.http.post(
      `${environment.base_url}merchant/updateShift`,
      payload
    );
  }
  getShiftsData(id: string): Observable<ShiftData[]> {
    return this.http
      .get<{ data: ShiftData[] }>(
        `${environment.base_url}merchant/getShiftsData/${id}`
      )
      .pipe(map(res => res.data));
  }

  updateInventoryStatus(payload: UpdateInventoryPayload): Observable<Merchant> {
    return this.http.post<Merchant>(
      `${environment.base_url}merchant/statusChangeInventory`,
      payload
    );
  }

  addAttributeValue(
    payload: Attributes
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this.http.post(
      `${environment.base_url}merchant/addAttributeValue`,
      payload
    );
  }
}
