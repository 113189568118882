import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { Sale } from 'src/app/models/sale';
import { SaleService } from 'src/app/services/sale.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'bazaarna-preview-data',
  templateUrl: './preview-data.component.html',
  styleUrls: ['./preview-data.component.scss']
})
export class PreviewDataComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialogRef: MatDialogRef<PreviewDataComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: Sale,
    private _spinnerService: SpinnerService,
    private _saleService: SaleService,
    private _toasterService: ToasterService,
    private _translateService: TranslateService
  ) {}

  discountAmount(element: Sale): string {
    const formattedDiscount =
      element.discountType === 'FLAT'
        ? `OMR ${element.discount}`
        : `${element.discount}%`;
    return formattedDiscount;
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this._spinnerService.addToLoader('addSales');
    this._saleService
      .addSale(this.data)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this._toasterService.successToastr(
            this._translateService.instant('SaleDiscountAdded')
          );
          this._spinnerService.removeFromLoader('addSales');
          this.dialogRef.close();
          this.router.navigateByUrl('manageSales');
        },
        error: () => {
          this._spinnerService.removeFromLoader('addSales');
        }
      });
  }
}
