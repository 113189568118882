import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { assetsConst } from 'src/app/core/const/assets.const';
import { responseStatus } from 'src/app/core/const/responseStatus.const';
import { fetchUserName } from 'src/app/core/helpers/fetchUserName.helper';
import { AdminUser } from 'src/app/models/adminUser';
import { logoutResponse } from 'src/app/models/logoutResponse';
import { notificationResponse } from 'src/app/models/noticationResponse';
import { notification } from 'src/app/models/notification';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SocketService } from 'src/app/services/socket.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'bazaarna-after-login',
  templateUrl: './after-login.component.html',
  styleUrls: ['./after-login.component.scss']
})
export class AfterLoginComponent implements OnInit, OnDestroy {
  sidenavWidth = 18;
  subscriptionSubject$!: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  profileDetails: AdminUser | null = null;
  assetsConst = assetsConst;
  dataSource: notification[] = [];
  audio = new Audio();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notificationWidget: any;
  fetchUserName = fetchUserName;

  constructor(
    private _authService: AuthService,
    private _dashboardService: DashboardService,
    private router: Router,
    private translateService: TranslateService,
    private _toasterService: ToasterService,
    private _socketService: SocketService,
    private _spinnerService: SpinnerService
  ) {
    this._socketService.initSocket();
    this.getNotifications();
    this.getNotificationWidget();
  }

  ngOnInit(): void {
    this.subscriptionSubject$ = this._authService.profileDetails
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: AdminUser | null) => {
        if (res !== null) {
          this.profileDetails = res;
        } else {
          this.getProfileDetails();
        }
      });
    this.handleListenersAndEmitters();
    this.handleListenersAndEmitters1();
  }

  handleListenersAndEmitters(): void {
    this._socketService
      .onEvent('newOrderOkMerchant')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: res => {
          this.playAudio();
          this.notificationWidget.unshift(res.data);
          this._toasterService.successToastr(
            this.translateService.instant('New Order Received')
          );
        }
      });
  }

  handleListenersAndEmitters1(): void {
    this._socketService
      .onEvent('returnOrder')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: res => {
          this.playAudio();
          this.notificationWidget.unshift(res.data);
          this._toasterService.successToastr(
            this.translateService.instant('Orderfor')
          );
        }
      });
  }

  playAudio(): void {
    this.audio.src = assetsConst.audioSource;
    this.audio.load();
    this.audio.play();
    this.audio.muted = false;
  }

  getNotificationWidget(): void {
    this._spinnerService.addToLoader('getNotificationWidget');
    this._dashboardService
      .getNotificationWidget()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: notificationResponse) => {
          this.notificationWidget = res.data;
          this._spinnerService.removeFromLoader('getNotificationWidget');
        },
        error: () => {
          this._spinnerService.removeFromLoader('getNotificationWidget');
        }
      });
  }

  getProfileDetails(): void {
    this._spinnerService.addToLoader('getProfile');
    this._authService
      .getMerchantProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: AdminUser) => {
          this._spinnerService.removeFromLoader('getProfile');
          this.profileDetails = res;
          this._authService.profileDetails.next(res);
        },
        error: () => {
          this._spinnerService.removeFromLoader('getProfile');
        }
      });
  }

  logout(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._authService.logoutAlert().then((res: any) => {
      if (
        Object.prototype.hasOwnProperty.call(res, 'value') &&
        res.isConfirmed === true
      ) {
        this._spinnerService.addToLoader('logout');
        this._authService
          .logout('')
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res: logoutResponse) => {
              if (res.statusCode === responseStatus.SUCCESS) {
                this._socketService.disconnect();
                localStorage.removeItem(environment.storageKey);
                this._spinnerService.removeFromLoader('logout');
                if (!localStorage.getItem(environment.storageKey)) {
                  this.router.navigate(['/auth/login']);
                }
              }
            }
          });
      }
    });
  }

  getNotifications(): void {
    const query = 'page=1&size=5';
    this._spinnerService.addToLoader('Notifications');
    this._dashboardService
      .getNotifications(query)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: notificationResponse) => {
          this._spinnerService.removeFromLoader('Notifications');
          this.dataSource = res.data;
        },
        error: () => {
          this._spinnerService.removeFromLoader('Notifications');
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
    this.subscriptionSubject$.unsubscribe();
  }
}
