import { Component } from '@angular/core';
import { assetsConst } from 'src/app/core/const/assets.const';

@Component({
  selector: 'bazaarna-nodatafound',
  templateUrl: './noDataFound.component.html',
  styleUrls: ['./noDataFound.component.scss']
})
export class NoDataFoundComponent {
  assetsConst = assetsConst;
}
