/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { paginationOutput } from 'src/app/models/paginationOutput';

@Component({
  selector: 'bazaarna-paginator',
  templateUrl: './paginator.component.html'
})
export class PaginatorComponent {
  private _recordsLength = 1;
  private _pageSize = 10;
  private _currentPage = 1;

  @Input()
  set recordsLength(value: number) {
    this._recordsLength = value;
  }

  get recordsLength(): number {
    return this._recordsLength;
  }

  @Input()
  set pageSize(value: number) {
    this._pageSize = value;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  @Input()
  set currentPage(value: number | null) {
    this._currentPage = value !== null && !isNaN(value) ? value : 1;
  }
  get currentPage(): number | null {
    return this._currentPage;
  }

  @Output() paginationOutput = new EventEmitter<any>();

  constructor() {
    // ToDo
  }

  fetchPageIndex(): number {
    if (this.currentPage !== null && !isNaN(this.currentPage)) {
      return this.currentPage - 1;
    } else {
      return 1;
    }
  }

  handlePage(event: PageEvent): void {
    const body: paginationOutput = {
      pageSize: event.pageSize,
      currentPage: event.pageIndex + 1
    };

    this.paginationOutput.emit(body);
  }
}
