export function fetchUserName(item: string): string {
  let nameString = '';
  if (item) {
    const newStringArr = item.split(' ');
    nameString =
      newStringArr[0].charAt(0) +
      (newStringArr.length > 1 ? newStringArr[1].charAt(0) : '');
  } else {
    nameString = 'BZ';
  }
  return nameString;
}
