// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: 'AIzaSyDK5nbdY6P3WpiCjGnDWN3Z-0AOiycE0Xk',
    authDomain: 'bazaarna-5f10b.firebaseapp.com',
    projectId: 'bazaarna-5f10b',
    storageBucket: 'bazaarna-5f10b.appspot.com',
    messagingSenderId: '654082685217',
    appId: '1:654082685217:web:2ab94ce1caa73eb3266660',
    vapidKey:
      'BLfXjColsCnHkehlpFqhvyjsCfkV8t1cQuViG1ZgpZ6i17MKff-FH_zWiUd0LXlUXOZEmjf1W7w_BejpBSNQjzQ',
    measurementId: 'G-M80V9FQYVQ'
  },
  production: false,
  base_url: 'https://testapi.bazaarna.co/api/v1/',
  socketUrl: 'https://testapi.bazaarna.co',
  replaceKey: 'bazaarna',
  setUpToken: 'setUpProfile',
  storageKey: 'bazaarnaMerchantAccessToken',
  rememberMeKey: 'isRememberMeBazaarnaMerchant',
  appId: '64b28b70be1bb83550343cfd',
  googleKey: 'AIzaSyDh6RcgnzvA83WOh4A0iq-tcQPKkhztG6c',
  path: '/firebase-messaging-sw.js',
  scope: '/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
