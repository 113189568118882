/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Order } from '../models/order';
import { OrderResponse } from '../models/orderResponse';
import { ExportCsvResponse } from '../core/const/exportCsvResponse';
import { SubstituteProductReponse } from '../models/substituteProducts';
import { ReturnOrder } from '../models/returnOrder';
import { Products } from '../models/products';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private http: HttpClient) {}

  getOrder(query: string): Observable<OrderResponse> {
    return this.http
      .get<{
        data: OrderResponse;
      }>(`${environment.base_url}merchant/getOrders?${query}`)
      .pipe(map(res => res.data));
  }

  groupOrders(query: string): Observable<OrderResponse> {
    return this.http
      .get<{
        data: OrderResponse;
      }>(`${environment.base_url}merchant/groupOrders?${query}`)
      .pipe(map(res => res.data));
  }

  getOrderForExchangeReturn(query: string): Observable<OrderResponse> {
    return this.http
      .get<{
        data: OrderResponse;
      }>(`${environment.base_url}merchant/exchangeReturn?${query}`)
      .pipe(map(res => res.data));
  }

  getOrderById(id: string): Observable<Order> {
    return this.http
      .get<{
        data: Order;
      }>(`${environment.base_url}merchant/getDetailsByOrderId/${id}`)
      .pipe(map(res => res.data));
  }

  productsAgainstExchange(id: string): Observable<Order> {
    return this.http
      .get<{
        data: Order;
      }>(`${environment.base_url}merchant/productsAgainstExchange/${id}`)
      .pipe(map(res => res.data));
  }

  updateOrder(payload: Order): Observable<Order> {
    return this.http
      .put<{
        data: Order;
      }>(`${environment.base_url}merchant/updateOrderStatus`, payload)
      .pipe(map(res => res.data));
  }

  updateReturnOrder(payload: ReturnOrder): Observable<Order> {
    return this.http
      .put<{
        data: Order;
      }>(`${environment.base_url}merchant/updateReturnOrder`, payload)
      .pipe(map(res => res.data));
  }

  updateExchangeOrder(payload: ReturnOrder): Observable<Order> {
    return this.http
      .put<{
        data: Order;
      }>(`${environment.base_url}merchant/updateExchangeOrder`, payload)
      .pipe(map(res => res.data));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOutletByOrderId(id: number): Observable<any> {
    return this.http.get(
      `${environment.base_url}merchant/getOutletByOrderId?orderId=${id}`
    );
  }

  assignOrderToDriver(payload: any, orderId: string): Observable<any> {
    return this.http.post(
      `${environment.base_url}merchant/assignDriver/${orderId}`,
      payload
    );
  }

  actionOnExchangeReturn(payload: Order, id: string): Observable<any> {
    return this.http.put(
      `${environment.base_url}merchant/approveRequestStatus/${id}`,
      payload
    );
  }

  getSubstituteProducts(
    id: string,
    query: string
  ): Observable<SubstituteProductReponse> {
    return this.http
      .get<{
        data: SubstituteProductReponse;
      }>(`${environment.base_url}merchant/getSubstitute/${id}?${query}`)
      .pipe(map(res => res.data));
  }

  getSubstituteProductsforSearch(
    id: string,
    query: string
  ): Observable<SubstituteProductReponse> {
    return this.http
      .get<{ data: SubstituteProductReponse }>(
        `${environment.base_url}merchant/searchSubstituteProducts/${id}?${query}`
      )
      .pipe(map(res => res.data));
  }


  rejectOrder(
    paylaod: { rejectionReason: string },
    id: string
  ): Observable<Order> {
    return this.http
      .post<{
        data: Order;
      }>(`${environment.base_url}merchant/rejectOrder/${id}`, paylaod)
      .pipe(map(res => res.data));
  }

  exportCsv(query: string): Observable<ExportCsvResponse> {
    return this.http.get<ExportCsvResponse>(
      `${environment.base_url}merchant/getOrdersCsvExport?${query}`
    );
  }

  exportExchangeReturnCsv(query: string): Observable<ExportCsvResponse> {
    return this.http.get<ExportCsvResponse>(
      `${environment.base_url}merchant/exchangeReturnCSV?${query}`
    );
  }

  suggestSubstituteToUser(
    id: string,
    data: { suggestedSubstitute: string[] }
  ): Observable<Products> {
    return this.http
      .post<{
        data: Products;
      }>(`${environment.base_url}merchant/sendSubstituteNotification/${id}`, data)
      .pipe(map(res => res.data));
  }
}
