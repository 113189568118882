<div class="moda_header">
  <bazaarna-search
    *ngIf="showSubstitute && isSubstituteSuggested()"
    (searchOutput)="fetchSearchOutput($event)"></bazaarna-search>
  <div
    class="substiute-btn"
    *ngIf="suggestedSubstitute.length && isSubstituteSuggested()">
    <button type="button" class="btn btn-primary" (click)="suggestSubstitute()">
      {{ 'SubstituteSuggest' | translate }}
    </button>
  </div>
</div>
<div class="d_flex" [ngClass]="{ 'full-width': !isSubstituteSuggested() }">
  <div class="tbl_lft">
    <ng-container *ngIf="dataSource && dataSource.length; else noData">
      <div class="tables">
        <mat-table #table [dataSource]="dataSource">
          <ng-container matColumnDef="#">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
              '#' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="image">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Image' | translate }}</mat-header-cell
            >

            <mat-cell *matCellDef="let element">
              <figure class="usr_img">
                <img
                  [src]="
                    element['productvariants'][0]['image'] &&
                    element['productvariants'][0]['image'].length
                      ? element['productvariants'][0]['image'][0]
                      : assetsConst.noDataFound
                  " />
              </figure>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Name' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="w_nrml">
              {{ element['productName'] }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="brand">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Brand' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              {{ element['brand']['name'] || '---' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Category' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              {{ element['category']['name'] || '---' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Price' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              {{ element['productvariants'][0]['sellingPrice'] || '---' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Action' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
              <ul class="action">
                <li class="eye">
                  <button
                    type="button"
                    (click)="onDeleteProduct(i, element)"
                    class="btn btn-primary edit_bt">
                    <i class="fa-solid fa-trash-can"></i>
                  </button>
                </li>
              </ul>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <!-- <bazaarna-paginator
        [recordsLength]="recordsLength"
        [pageSize]="pageSize"
        [currentPage]="currentPage"
        (paginationOutput)="fetchPaginationOutput($event)"></bazaarna-paginator> -->
      </div>
    </ng-container>

    <ng-template #noData>
      <bazaarna-nodatafound></bazaarna-nodatafound>
    </ng-template>
  </div>
  <div *ngIf="showSubstituteSeaarch && isSubstituteSuggested()" class="filter_wrp">
    <bazaarna-search
      (searchOutput)="fetchSearchOutput($event)"></bazaarna-search>
    <ng-container *ngIf="dataSourceSearch && dataSourceSearch.length">
      <div class="tables">
        <mat-table #table [dataSource]="dataSourceSearch">
          <ng-container matColumnDef="image">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'image' | translate }}</mat-header-cell
            >

            <mat-cell *matCellDef="let element">
              <figure class="usr_img">
                <img
                  [src]="
                    element['productvariants'][0]['image'] &&
                    element['productvariants'][0]['image'].length
                      ? element['productvariants'][0]['image'][0]
                      : assetsConst.noDataFound
                  " />
              </figure>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Name' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="w_nrml">
              {{ element['productName'] }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'Action' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
              <ul class="action">
                <li *ngIf="element.isSelected" class="eye">
                  <button type="button" class="btn btn-primary delete_bt">
                    {{ 'Added' | translate }}
                  </button>
                </li>
                <li *ngIf="!element.isSelected" class="eye">
                  <button
                    type="button"
                    class="btn btn-primary delete_bt"
                    (click)="onAddProduct(element)">
                    <mat-icon class="add_but">add</mat-icon>
                  </button>
                </li>
              </ul>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsForSearch"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsForSearch"></mat-row>
        </mat-table>
        <!-- <bazaarna-paginator
          [recordsLength]="recordsLength"
          [pageSize]="pageSize"
          [currentPage]="currentPage"
          (paginationOutput)="
            fetchPaginationOutputForSearch($event)
          "></bazaarna-paginator> -->
      </div>
    </ng-container>
  </div>
</div>
