import { SideBar } from 'src/app/models/sideBar';
import { assetsConst } from './assets.const';

export const SideBarConst: Array<SideBar> = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: assetsConst.dash,
    isSubMenu: false
  },
  {
    text: 'Outlets',
    path: '/manage-outlet',
    icon: assetsConst.order5,
    ang_icon: 'view_quilt',
    isSubMenu: false
  },
  {
    text: 'Brand Management',
    path: '/brand',
    icon: assetsConst.payment,
    isSubMenu: false
  },
  {
    text: 'Orders',
    icon: assetsConst.manage_order,
    ang_icon: 'store',
    isSubMenu: true,
    subMenuItems: [
      {
        text: 'Orders',
        path: '/manage-order',
        icon: assetsConst.order3,
        isSubMenu: false
      },
      {
        text: 'Exchange/Return',
        path: '/manage-order/return/0/1',
        icon: assetsConst.order2,
        isSubMenu: false
      }
    ]
  },
  {
    text: 'Products',
    path: '/product',
    icon: assetsConst.cube,
    ang_icon: 'view_quilt',
    isSubMenu: false
  },
  {
    text: 'Vehicles',
    path: '/vehicles',
    icon: assetsConst.vehicle,
    isSubMenu: false
  },
  {
    text: 'Drivers',
    path: '/drivers/list/1',
    icon: assetsConst.driver,
    isSubMenu: false
  },
  {
    text: 'Promotions/Discounts',
    path: '/manageSales',
    icon: assetsConst.discount,
    ang_icon: 'Sales',
    isSubMenu: false
  },
  {
    text: 'Ratings and Reviews',
    path: '/manage-review',
    icon: assetsConst.star,
    ang_icon: 'store',
    isSubMenu: false
  },
  {
    text: 'Manage Reports',
    path: '/manage-reports',
    icon: assetsConst.reports,
    ang_icon: 'circle_notifications',
    isSubMenu: false
  }
];
