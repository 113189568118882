import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExportCsvResponse } from '../core/const/exportCsvResponse';
import { BrandListRespone } from '../models/brandListResponse';
import { Brands } from '../models/brands';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  constructor(private http: HttpClient) {}

  getBrandListing(queryString: string): Observable<BrandListRespone> {
    return this.http
      .get<{ data: BrandListRespone }>(
        `${environment.base_url}merchant/getBrand?${queryString}`
      )
      .pipe(map(res => res.data));
  }

  getBrandById(brandId: string): Observable<Brands> {
    return this.http
      .get<{ data: Brands }>(
        `${environment.base_url}merchant/getBrand/${brandId}`
      )
      .pipe(map(res => res.data));
  }

  getBrandDropDown(): Observable<Brands[]> {
    return this.http
      .get<{ data: Brands[] }>(`${environment.base_url}merchant/brandDropdown`)
      .pipe(map(res => res.data));
  }

  addBrand(payload: Brands): Observable<Brands> {
    return this.http
      .post<{ data: Brands }>(
        `${environment.base_url}merchant/addBrand`,
        payload
      )
      .pipe(map(res => res.data));
  }

  updateBrand(payload: Brands, id: string): Observable<Brands> {
    return this.http
      .put<{ data: Brands }>(
        `${environment.base_url}merchant/updateBrand/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  updateBrandStatus(
    payload: { isBlocked: boolean },
    id: string
  ): Observable<Brands> {
    return this.http
      .put<{ data: Brands }>(
        `${environment.base_url}merchant/updateBrandStatus/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  deleteBrand(data: { deleteBrands: string[] }): Observable<Brands> {
    return this.http
      .delete<{ data: Brands }>(`${environment.base_url}merchant/deleteBrand`, {
        body: data
      })
      .pipe(map(res => res.data));
  }

  exportCsv(query: string): Observable<ExportCsvResponse> {
    return this.http.get<ExportCsvResponse>(
      `${environment.base_url}merchant/brandCSV?${query}`
    );
  }
}
