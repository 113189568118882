import { keyValuePair } from 'src/app/models/keyValuePair';

export const driverTypeNumber = {
  BAZAARNA_DRIVER: 0,
  SELF_DRIVER: 1
};

export const driverType: Array<keyValuePair> = [
  {
    key: 'Bazaarna Driver',
    value: driverTypeNumber.BAZAARNA_DRIVER
  },
  {
    key: 'Merchant Driver',
    value: driverTypeNumber.SELF_DRIVER
  }
];

export const salaryTypeNumber = {
  SALARIED: 1,
  COMMISSIONED: 2,
  SALARIEDCOMMISSIONED: 3
};