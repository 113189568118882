/* eslint-disable @typescript-eslint/no-explicit-any */
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatListModule } from '@angular/material/list';
import { provideFirebaseApp } from '@angular/fire/app';
import { provideAuth } from '@angular/fire/auth';
import { getAuth } from '@firebase/auth';

import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { AfterLoginComponent } from './layout/after-login/after-login.component';
import { ModalsModule } from './modals/modals.module';
import { GetInterceptorService } from './core/interceptor/get-interceptor.service';
import { SetInterceptorService } from './core/interceptor/set-interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { InitialService } from './services/initial.service';
import { environment } from 'src/environments/environment';
// import { ServiceWorkerRegister } from './core/service-worker/serviceWorker';
import { AfterLoginShared } from './shared/after-login.shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { initializeApp } from 'firebase/app';
initializeApp(environment.firebase);
// loader module
export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

type FactoryFunction = () => void;

export function initialServiceFactory(
  InitialService: InitialService
): FactoryFunction {
  return () => InitialService.setUpGoogleScript();
}

// export function startupServiceFactory(
//   InitialService: ServiceWorkerRegister
//   // eslint-disable-next-line @typescript-eslint/ban-types
// ): Function {
//   return () => InitialService.publicApi();
// }

@NgModule({
  declarations: [AppComponent, AfterLoginComponent, SidebarComponent],
  exports: [TranslateModule],
  providers: [
    CookieService,
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialServiceFactory,
      deps: [InitialService],
      multi: true
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: startupServiceFactory,
    //   deps: [ServiceWorkerRegister],
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    MatInputModule,
    MatTabsModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSidenavModule,
    HttpClientModule,
    MatTooltipModule,
    MatExpansionModule,
    MatListModule,
    NgOtpInputModule,
    ModalsModule,
    NgxUiLoaderModule,
    GooglePlaceModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    AfterLoginShared,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      registrationStrategy: 'registerImmediately'
    })
  ]
})
export class AppModule {}
