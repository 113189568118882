import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { assetsConst } from 'src/app/core/const/assets.const';
import { ImageUploadService } from 'src/app/services/uploadImage.service';

@Component({
  selector: 'bazaarna-image-viewer',
  templateUrl: './image-viewer.component.html'
})
export class ImageViewerComponent {
  showImput = false;
  assetsConst = assetsConst;
  hightlighted = false;
  image = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private _uploadImageService: ImageUploadService
  ) {
    if (!data) {
      this.showImput = true;
    }
  }

  onFileChange(event: Event): void {
    this._uploadImageService
      .onFileChange(event, '')
      .then((res: { data: string }) => {
        if (res !== null) {
          this.image = res['data'];
          this.hightlighted = false;
        }
      });
  }
  highlight(): void {
    this.hightlighted = true;
  }
}
