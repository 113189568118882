/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExportCsvResponse } from '../core/const/exportCsvResponse';
import { DocData } from '../models/DocData';
import { DriverDocsResponse } from '../models/MerchantDocsResponse';

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  DocsData: DocData = {
    documentName: '',
    isIssueRequired: false,
    isFrontImageRequired: false,
    isBackImageRequired: false,
    isExpiryRequired: false,
    _id: null,
    frontImage: '',
    backImage: '',
    expiryDate: '',
    issueDate: ''
  };
  constructor(private http: HttpClient) {}

  getDriverDocs(): Observable<DriverDocsResponse> {
    return this.http
      .get<{ data: DriverDocsResponse }>(
        `${environment.base_url}merchant/getDriverDocsFromAdmin`
      )
      .pipe(map(res => res.data));
  }

  getDriver(query: string): Observable<any> {
    return this.http.get(`${environment.base_url}merchant/getDriver?${query}`);
  }

  getAvailableDriver(id: string): Observable<any> {
    return this.http.get(
      `${environment.base_url}merchant/driversThroughZone/${id}`
    );
  }

  getDriverById(id: string): Observable<any> {
    return this.http.get(`${environment.base_url}merchant/getDriverById/${id}`);
  }

  getAllMerchants(): Observable<any> {
    return this.http.get(`${environment.base_url}merchant/merchantDropdown`);
  }

  addDriver(payload: any): Observable<any> {
    return this.http.post(`${environment.base_url}merchant/driver`, payload);
  }

  updateDriver(payload: any, driverId: string): Observable<any> {
    return this.http.put(
      `${environment.base_url}merchant/updateDriverById/${driverId}`,
      payload
    );
  }

  updateDriverStatus(payload: any): Observable<any> {
    return this.http.put(
      `${environment.base_url}merchant/approveDriver/${payload.driverId ? payload.driverId : payload._id}`,
      payload
    );
  }
  
  preventPaste(event: any): void {
    event.preventDefault();
  }

  toggleDriverUpdate(payload: any): Observable<any> {
    return this.http.put(
      `${environment.base_url}merchant/driverStatusChange/${payload.driverId}`,
      { isBlocked: payload.isBlocked }
    );
  }

  deleteDriver(id: string): Observable<number> {
    return this.http
      .delete<{ statusCode: number }>(
        `${environment.base_url}merchant/deleteDriverById/${id}`
      )
      .pipe(map(res => res.statusCode));
  }
  exportCsv(query: string): Observable<ExportCsvResponse> {
    return this.http.get<ExportCsvResponse>(
      `${environment.base_url}merchant/getDriverCsvExport?${query}`
    );
  }

  getGeoFenceListingAll(): Observable<any> {
    return this.http.get(`${environment.base_url}merchant/geofence`);
  }
}
