/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { logoutResponse } from 'src/app/models/logoutResponse';
import { SideBar } from 'src/app/models/sideBar';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';
import { assetsConst } from '../../const/assets.const';
import { responseStatus } from '../../const/responseStatus.const';
import { SideBarConst } from '../../const/sideBarConst';
import { SocketService } from 'src/app/services/socket.service';
import { approvalStatus } from '../../const/approvalStatus.const';
import { Merchant } from 'src/app/models/merchant';

@Component({
  selector: 'bazaarna-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy {
  @Output() urlOutput = new EventEmitter<any>();

  panelOpenState = false;
  sidenavWidth = 15;
  subscriptionSubject$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  sideBar = SideBarConst;
  assetsConst = assetsConst;
  approvalStatusConst = approvalStatus;
  profileData!: Merchant;

  constructor(
    private _authService: AuthService,
    private router: Router,
    private _socketService: SocketService,
    private _spinnerService: SpinnerService
  ) {
    this.subscriptionSubject$ = this._authService.profileDetails
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res !== null) {
          this.profileData = res;
        }
      });
  }

  fadeClass(el: SideBar): boolean {
    const specialItems = [
      'Orders',
      'Vehicles',
      'Drivers',
      'Promotions/Discounts',
      'Ratings and Reviews',
      'Manage Reports'
    ];

    const isSpecialItem = specialItems.includes(el.text);

    return (
      isSpecialItem &&
      this.profileData?.isApprovedByAdmin === this.approvalStatusConst.Pending
    );
  }

  fetchEvent(item: SideBar): void {
    if (item.text === 'Logout') {
      this._authService.logoutAlert().then((res: any) => {
        if (
          Object.prototype.hasOwnProperty.call(res, 'value') &&
          res.isConfirmed === true
        ) {
          this._spinnerService.addToLoader('logout');
          this._authService
            .logout('')
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (res: logoutResponse) => {
                if (res.statusCode === responseStatus.SUCCESS) {
                  this._socketService.disconnect();
                  localStorage.removeItem(environment.storageKey);
                  this._spinnerService.removeFromLoader('logout');
                  this.router.navigate(['/auth/login']);
                }
              }
            });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
