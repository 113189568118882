import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  DashboardResponse,
  graphsResponseData
} from '../models/dashboardResponse';
import { notificationResponse } from '../models/noticationResponse';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getDashboardData(
    filter: string | number,
    data: any
  ): Observable<DashboardResponse> {
    return this.http
      .post<{ data: DashboardResponse }>(
        `${environment.base_url}merchant/dashboard${filter}`,
        data
      )
      .pipe(map(res => res.data));
  }

  getNotifications(query: string): Observable<notificationResponse> {
    return this.http
      .get<{ data: notificationResponse }>(
        `${environment.base_url}merchant/getNotifications?${query}`
      )
      .pipe(map(res => res.data));
  }

  getMerchantOutlets(
    query: string
  ): Observable<{ storeName: string; _id: string }> {
    return this.http
      .get<{ data: { storeName: string; _id: string } }>(
        `${environment.base_url}merchant/outletDropdown?${query}`
      )
      .pipe(map(res => res.data));
  }

  getNotificationWidget(): Observable<notificationResponse> {
    return this.http
      .get<{ data: notificationResponse }>(
        `${environment.base_url}merchant/getNotificationWidget`
      )
      .pipe(map(res => res.data));
  }

  getChartsData(payload: {
    graphType: string;
    type: string;
  }): Observable<graphsResponseData[]> {
    return this.http
      .post<{ data: graphsResponseData[] }>(
        `${environment.base_url}merchant/changeGraphStatus`,
        payload
      )
      .pipe(map(res => res.data));
  }
}
