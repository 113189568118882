import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { translationResponse } from '../models/translateText';

@Injectable({
  providedIn: 'root'
})
export class TranslateTextService {
  constructor(private http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translateApi(payload: any): Observable<translationResponse> {
    return this.http
      .post<{
        data: translationResponse;
      }>('https://google-translate1.p.rapidapi.com/language/translate/v2', payload)
      .pipe(map(res => res.data));
  }
}
