import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { VerifyEmailOrPhone } from 'src/app/models/VerifyEmailOrPhone';
import { AdminUser } from 'src/app/models/adminUser';
import { signUpBody } from 'src/app/models/signUpBody';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'bazaarna-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnDestroy {
  code = '';
  timer = 59;
  destroy$: Subject<boolean> = new Subject<boolean>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intervalHandle: any;
  d_t_: signUpBody = {
    key: '',
    password: ''
  };

  constructor(
    public dialogRef: MatDialogRef<VerifyOtpComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: VerifyEmailOrPhone,
    private _authService: AuthService,
    private _spinnerService: SpinnerService,
    private _toasterService: ToasterService
  ) {
    const d_t_ = localStorage.getItem('d_t_');
    if (d_t_) {
      this.d_t_ = JSON.parse(d_t_);
    }
    this.startTimer();
  }

  onOtpChange(event: string): void {
    this.code = event;
  }

  onSubmit(): void {
    if (this.code.length !== 4) {
      this._toasterService.errToastr(
        this.translateService.instant('VaidateLength')
      );
      return;
    }
    const payload = {
      code: this.code,
      key: this.data.key,
      [this.data.type === 'phone' ? 'countryCode' : '']: this.data?.countryCode
    };

    this._spinnerService.addToLoader('verify');
    this._authService
      .verifyMailOrPhoneOtp(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: AdminUser) => {
          this._spinnerService.removeFromLoader('verify');
          this.dialogRef.close({ ...res,
            type: this.data.type });
        },
        error: () => {
          this._spinnerService.removeFromLoader('verify');
        }
      });
  }

  startTimer(): void {
    this.intervalHandle = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
        sessionStorage.setItem(
          'verificationTimerValue',
          JSON.stringify(this.timer)
        );
      } else {
        clearInterval(this.intervalHandle);
      }
    }, 1000);
  }

  resendOtp(): void {
    const body: signUpBody = {
      key: '',
      type: '',
      password: ''
    };
    if (this.data.key) {
      if (this.data.type === 'phone') {
        Object.assign(body, {
          key: this.data.key,
          countryCode: this.data?.countryCode,
          type: 'resend',
          password: this.d_t_.password
        });
      } else {
        Object.assign(body, {
          key: this.data.key,
          type: 'resend',
          password: this.d_t_.password
        });
      }
    }
    this._spinnerService.addToLoader('resend');
    this._authService
      .signUpMerchant(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this._spinnerService.removeFromLoader('resend');
          this._toasterService.successToastr(
            this.translateService.instant('OTP_RESENT')
          );
          sessionStorage.removeItem('verificationTimerValue');
          this.timer = 59;
          this.startTimer();
        },
        error: () => {
          this._spinnerService.removeFromLoader('resend');
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
