/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';

@Component({
  selector: 'bazaarna-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements AfterViewInit {
  @ViewChild('search')
    searchRef!: ElementRef;

  showCrossIcon = false;
  search = '';

  @Output() searchOutput: EventEmitter<string | null> = new EventEmitter();
  constructor() {
    // ToDo
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchRef.nativeElement, 'keyup')
      .pipe(
        map((i: any) => i.target.value),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((res: any) => {
        if (res) {
          this.showCrossIcon = true;
          this.search = res;
          this.searchOutput.emit(res.trim());
        } else {
          this.showCrossIcon = false;
          this.search = '';
          this.searchOutput.emit(res);
        }
      });
  }

  clearInputField(): void {
    this.searchRef.nativeElement.value = '';
    this.search = '';
    this.showCrossIcon = false;
    this.searchOutput.emit(null);
  }
}
