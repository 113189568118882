import { keyValuePair } from 'src/app/models/keyValuePair';

export const salaryTypeNumber = {
  SALARIED: 1,
  COMMISSIONED: 2,
  SALARIEDCOMMISSIONED: 3
};

export const salaryType: Array<keyValuePair> = [
  {
    key: 'Salaried',
    value: salaryTypeNumber.SALARIED
  },
  {
    key: 'Commissioned',
    value: salaryTypeNumber.COMMISSIONED
  },
  {
    key: 'Salaried + Commissioned',
    value: salaryTypeNumber.SALARIEDCOMMISSIONED
  }
];
