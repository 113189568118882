import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BrandsArrayResponse } from '../models/BrandsArrayResponse';
import { Sale } from '../models/sale';
import {
  SaleDetails,
  SaleListingResponse
} from '../models/salelistingResponse';

@Injectable({
  providedIn: 'root'
})
export class SaleService {
  constructor(private http: HttpClient) {}

  getBrandsWithProductId(): Observable<BrandsArrayResponse[]> {
    return this.http
      .get<{ data: BrandsArrayResponse[] }>(
        `${environment.base_url}merchant/getBrandsWithProductId`
      )
      .pipe(map(res => res.data));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  NumOnly(event: any): void {
    const inputText = event.target.value.replace(/[^0-9.]/g, '');
    const decimalIndex = inputText.indexOf('.');

    if (decimalIndex !== -1) {
      const integerPart = inputText.slice(0, decimalIndex); // Extract the integer part
      const decimalPart = inputText.slice(decimalIndex + 1); // Extract the decimal part
      event.target.value = `${integerPart}.${decimalPart.slice(0, 2)}`; // Limit decimal upto 2 digits
    } else {
      event.target.value = inputText.slice(0, 10);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preventPaste(event: any): void {
    event.preventDefault();
  }

  addSale(payload: Sale): Observable<Sale> {
    return this.http
      .post<{ data: Sale }>(
        `${environment.base_url}merchant/createSale`,
        payload
      )
      .pipe(map(res => res.data));
  }

  getSale(query: string): Observable<SaleListingResponse> {
    return this.http
      .get<{ data: SaleListingResponse }>(
        `${environment.base_url}merchant/getSale?${query}`
      )
      .pipe(map(res => res.data));
  }

  updateSale(
    payload: { isBlocked: boolean },
    id: string
  ): Observable<SaleDetails> {
    return this.http
      .put<{ data: SaleDetails }>(
        `${environment.base_url}merchant/updateStatusSale/${id}`,
        payload
      )
      .pipe(map(res => res.data));
  }

  deleteSale(id: string): Observable<number> {
    return this.http
      .delete<{ statusCode: number }>(
        `${environment.base_url}merchant/deleteSale/${id}`
      )
      .pipe(map(res => res.statusCode));
  }
}
