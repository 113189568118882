/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {
  private destroy$ = new Subject<void>();
  constructor(
    private http: HttpClient,
    private _spinnerService: SpinnerService
  ) {}

  onFileChange(event: any, type: string): Promise<any> {
    return new Promise((resolve: any) => {
      if (
        event.target.files &&
        event.target.files[0] &&
        event.target.files[0].type.startsWith('image/')
      ) {
        const formData = new FormData();

        formData.append('image', event.target.files[0]);

        this._spinnerService.addToLoader('uploadImage');
        this.http
          .post(`${environment.base_url}merchant/upload`, formData)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res: any) => {
              this._spinnerService.removeFromLoader('uploadImage');
              return resolve(res);
            },
            error: () => {
              this._spinnerService.removeFromLoader('uploadImage');
            }
          });
      } else if (
        event.target.files &&
        event.target.files[0] &&
        event.target.files[0].type.startsWith('video/') &&
        type === 'product'
      ) {
        const formData = new FormData();

        formData.append('image', event.target.files[0]);

        this._spinnerService.addToLoader('uploadVideo');
        this.http
          .post(`${environment.base_url}merchant/upload`, formData)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res: any) => {
              this._spinnerService.removeFromLoader('uploadVideo');
              return resolve(res);
            },
            error: () => {
              this._spinnerService.removeFromLoader('uploadVideo');
            }
          });
      } else if (
        event.target.files &&
        event.target.files[0] &&
        (event.target.files[0].type.startsWith('application/') ||
          event.target.files[0].type.startsWith('text/')) &&
        type === 'category'
      ) {
        const formData = new FormData();

        formData.append('image', event.target.files[0]);

        this._spinnerService.addToLoader('uploadexcel');
        this.http
          .post(`${environment.base_url}merchant/upload`, formData)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res: any) => {
              this._spinnerService.removeFromLoader('uploadexcel');
              return resolve(res);
            },
            error: () => {
              this._spinnerService.removeFromLoader('uploadexcel');
            }
          });
      }
    });
  }

  importProductFile(payload: { url: string }): Observable<any> {
    return this.http
      .post<{
        payload: any;
      }>(`${environment.base_url}merchant/product/import`, payload)
      .pipe(map(res => res.payload));
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
