import { NgModule } from '@angular/core';
import { NoDataFoundComponent } from './components/noDataFound/noDataFound.component';

// To Do
@NgModule({
  declarations: [NoDataFoundComponent],
  imports: [],
  exports: [NoDataFoundComponent]
})
export class AfterLoginShared {}
