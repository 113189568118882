import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';
import { driverTypeNumber } from 'src/app/core/const/driverType.const';
import { orderDeliveryConst } from 'src/app/core/const/orderDelivery.const';
import { orderStatusKeysConst } from 'src/app/core/const/orderStatusKeys.const';
import { staticText } from 'src/app/core/const/staticText.const';
import { Order } from 'src/app/models/order';
import { OrderService } from 'src/app/services/order.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SubstituteOrderComponent } from '../substitute-order/substitute-order.component';

@Component({
  selector: 'bazaarna-order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss']
})
export class OrderInvoiceComponent implements OnChanges, OnInit {

  private _orderNumber!: string;
  private _substituteDialog = {isDialogClosed: false,
    isSuggestSubstituteUndefined: false};
  private _orderStatusChangedFromChild = false;  

  @Input()
  set orderNumber(value: string) {
    this._orderNumber = value;
  }

  get orderNumber(): string {
    return this._orderNumber;
  }

  @Input()
  set substituteDialog (value: {isDialogClosed: boolean,
    isSuggestSubstituteUndefined: boolean}) {
    this._substituteDialog = value;
  }

  get substituteDialog(): {isDialogClosed: boolean,
    isSuggestSubstituteUndefined: boolean} {
    return this._substituteDialog;
  }
  
  @Input()
  set orderStatusChangedFromChild (value: boolean) {
    this._orderStatusChangedFromChild = value;
  }

  get orderStatusChangedFromChild(): boolean {
    return this._orderStatusChangedFromChild;
  }

  staticText = staticText;
  orderStatus = orderStatusKeysConst;
  deliveryConst = orderDeliveryConst;
  isDetailVisible = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderDetails: any;
  showOrderSummary = false;

  @Output() selectionEvent = new EventEmitter();
  @Output() suggestSubstituteProduct = new EventEmitter();
  @Output() changeOrderStatusValueForParent = new EventEmitter();

  constructor(
    private _spinnerService: SpinnerService,
    private _orderService: OrderService,
    private dialog: MatDialog
  ) {}

  ngOnChanges(): void {
    if (this.substituteDialog.isDialogClosed && this.substituteDialog.isSuggestSubstituteUndefined) {
      this.groupOrders();
    }

    if (this.orderStatusChangedFromChild) {
      this.groupOrders();
    }
  }

  ngOnInit(): void {
    this.groupOrders();
  }

  fetchTotalAmount(): number {
    let sum = 0;
    this.orderDetails?.subOrders?.forEach((element: Order) => {
      sum += ((element.basePriceOfProduct ? element.basePriceOfProduct : 0) * (element.quantity ? element.quantity : 0));
    });

    return sum;
  }

  fetchGrandAmount(): number {

    
    let sum = 0;
    this.orderDetails?.subOrders?.forEach((element: Order) => {
      
      if (element.status !== orderStatusKeysConst.Cancelled && element.status !== orderStatusKeysConst.Rejected && element.status !== orderStatusKeysConst.SUBSTITUTE) {
        sum += (((element.basePriceOfProduct ? element.basePriceOfProduct : 0) * (element.quantity ? element.quantity : 0)) + element.vatAmount);
      } 
    });

    return sum > 0 ? sum : 0;
  }

  fetchGiftAmount(): number {
    let sum = 0;
    this.orderDetails?.subOrders?.forEach((element: Order) => {
      sum += element.bazaarnaPackagePrice;
    });

    return sum;
  }

  fetchBaseAmount(): number {
    let sum = 0;
    this.orderDetails?.subOrders?.forEach((element: Order) => {
      sum += element.basePackagePrice;
    });

    return sum;
  }

  fetchVatAmount(): number {
    let sum = 0;
    this.orderDetails?.subOrders?.forEach((element: Order) => {
      sum += element.vatAmount;
    });

    return sum;
  }

  fetchCancelledOrderAmount(): number {
    let sum = 0;

    this.orderDetails?.subOrders?.forEach((element: Order) => {
      if (element.status === orderStatusKeysConst.Cancelled) {
        sum += (((element.basePriceOfProduct ? element.basePriceOfProduct : 0) * (element.quantity ? element.quantity : 0)) + element.vatAmount);
      }
    });

    return sum;
  }

  fetchSubstituteOrderAmount(): number {
    let sum = 0;

    this.orderDetails?.subOrders?.forEach((element: Order) => {
      if (element.status === orderStatusKeysConst.SUBSTITUTE) {
        sum += (((element.basePriceOfProduct ? element.basePriceOfProduct : 0) * (element.quantity ? element.quantity : 0)) + element.vatAmount);
      }
    });

    return sum;
  }

  fetchRejectedOrderAmount(): number {
    let sum = 0;
    this.orderDetails?.subOrders?.forEach((element: Order) => {
      
      if (element.status === orderStatusKeysConst.Rejected) {
        sum += (((element.basePriceOfProduct ? element.basePriceOfProduct : 0) * (element.quantity ? element.quantity : 0)) + element.vatAmount);
      }
    });
    return sum;
  }

  groupOrders(): void {
    this._spinnerService.addToLoader('getOrderDetails');
    
    const query = `orderNumber=${this.orderNumber}`;
    this._orderService
      .groupOrders(query)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (res: any) => {
          this.orderDetails = res[0];
          for (const item of this.orderDetails.subOrders) {
            /**
             * Status was getting undefined so this key was added
             */
            item.statusNew = JSON.parse(JSON.stringify(item.status));
            /** */
            if ((item.status >= orderStatusKeysConst.PickedByDriver) && item.deliveryBy === driverTypeNumber.SELF_DRIVER) {
              this.isDetailVisible = true;
              break;
            }

            if (item.status > orderStatusKeysConst.Pending) {
              this.showOrderSummary = true;
              break;
            }
            this.changeOrderStatusValueForParent.emit(false);
          }
          this._spinnerService.removeFromLoader('getOrderDetails');
        },
        error: () => {
          this._spinnerService.removeFromLoader('getOrderDetails');
        }
      });
  }

  onStatusChange(event: MatSelectChange, item: Order): void {
    this.selectionEvent.emit({ ...event,
      item });
  }

  suggestSubstitute(id: string): void {
    this.suggestSubstituteProduct.emit(id);
  }

  viewSubstitute(id: string, status: number): void {
    this.dialog.open(SubstituteOrderComponent, {
      panelClass: ['subs_modal'],
      data: { id,
        status }
    });
  }
}
