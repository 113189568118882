<div class="sec_head">
  <mat-label> {{ 'ChooseDriver' | translate }}</mat-label>
</div>
<div class="form_grup w_400">
  <mat-select
    placeholder="{{ 'Action' | translate }}"
    (selectionChange)="onStatusChange($event)">
    <mat-option *ngFor="let item of driverType" [value]="item.value">
      {{ item.key }}
    </mat-option>
  </mat-select>
</div>

<div class="filter_lft">
  <button class="btn btn-primary" (click)="updateStatus()" type="submit">
    {{ 'Submit' | translate }}
  </button>
</div>
