<div class="modal_title">
  <h1>
    {{ (data?.type === 'color' ? 'addColor' : 'addSize') | translate }}
  </h1>
</div>

<form
  class="input_field"
  [formGroup]="attributeFormGroup"
  (ngSubmit)="onSubmit()">
  <div class="tables">
    <div class="form_feilds">
      <div class="form_grup w_100">
        <mat-label>
          {{ 'Label' | translate }}
          <span class="astrick"></span
        ></mat-label>
        <mat-form-field appearance="fill">
          <input
            matInput
            [placeholder]="'Label' | translate"
            class="form_control"
            formControlName="label" />
        </mat-form-field>
        <div>
          <mat-error
            *ngIf="
              submitted && attributeFormGroup.get('label')?.hasError('required')
            ">
            {{ 'Label' | translate }}
            {{ 'IsRequired' | translate }}
          </mat-error>
        </div>
      </div>
      <div class="form_grup w_100">
        <mat-label>{{ 'Value' | translate }}</mat-label>
        <mat-form-field appearance="fill">
          <input
            [type]="data?.type === 'color' ? 'color' : 'text'"
            matInput
            placeholder="{{ 'Value' | translate }}"
            class="form_control"
            formControlName="value" />
        </mat-form-field>
        <mat-error
          *ngIf="
            submitted && attributeFormGroup.get('value')?.hasError('required')
          ">
          {{ 'Value' | translate }}
          {{ 'IsRequired' | translate }}
        </mat-error>
      </div>
    </div>
    <div class="filter_lft">
      <button class="btn btn-primary" type="submit">
        {{ 'Save' | translate }}
      </button>
    </div>
  </div>
</form>
