<div class="reason_popup">
  <button type="button"  class="btnn btn_danger cross_btn" (click)="closeDialog()">
    <i class="fa-solid fa-xmark"></i>
  </button>
  <div class="form_grup">
    <mat-label>
      <h4>{{ 'RevertReview' | translate }}</h4>
    </mat-label>
    <div class="form_group">
      <mat-form-field appearance="fill">
        <textarea
          matInput
          placeholder="{{ 'RevertReview' | translate }}"
          class="form_control"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="submit mt_20">
    <button type="button"  class="btnn btn_primary">{{ 'Submit' | translate }}</button>
  </div>
</div>
