import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, catchError, takeUntil, tap } from 'rxjs';
import { Brands } from 'src/app/models/brands';
import { translationResponse } from 'src/app/models/translateText';
import { BrandService } from 'src/app/services/brand.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { TranslateTextService } from 'src/app/services/translateText.service';

@Component({
  selector: 'bazaarna-add-brand',
  templateUrl: './add-brand.component.html'
})
export class AddBrandComponent implements OnInit, OnDestroy {
  submitted = false;
  brandFormGroup: FormGroup;
  type: string | null = null;
  brandId: string | null = null;
  previousPage: string | number | null = 1;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private _spinnerService: SpinnerService,
    private _brandService: BrandService,
    private _translateTextService: TranslateTextService,
    private _toasterService: ToasterService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<AddBrandComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { type: string; brandData: Brands } | null
  ) {
    this.brandFormGroup = this.formBuilder.group({
      name_ar: ['', [Validators.required]],
      name: ['', [Validators.required]],
      tradeMarkImg: ['']
    });

    if (this.data !== null) {
      this.type = this.data.type;
      this.brandId =
        this.data.brandData !== null && this.data.brandData._id !== undefined
          ? this.data.brandData._id
          : null;
    }
  }

  ngOnInit(): void {
    if (this.brandId !== null) {
      this.getBrandDetail();
    }
  }

  getBrandDetail(): void {
    this._brandService
      .getBrandById(this.brandId !== null ? this.brandId : '')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: Brands) => {
          this.brandFormGroup.patchValue(res);
        }
      });
  }

  translate(control: string, controlValue: string): void {
    const targetLanguage = control.includes('_ar') ? 'en' : 'ar';
    const sourceLanguage = targetLanguage === 'en' ? 'ar' : 'en';
    const targetControl =
      targetLanguage === 'en' ? control.split('_ar')[0] : `${control}_ar`;

    const body = new URLSearchParams({
      q: controlValue,
      target: targetLanguage,
      source: sourceLanguage
    });

    this._spinnerService.addToLoader('translateText');
    this._translateTextService
      .translateApi(body)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this._spinnerService.removeFromLoader('translateText')),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catchError((error: any) => {
          this._spinnerService.removeFromLoader('translateText');
          throw error;
        })
      )
      .subscribe({
        next: (res: translationResponse) => {
          this._toasterService.successToastr(
            this.translateService.instant('textTranslatedSuccess')
          );
          this.brandFormGroup.patchValue({
            [targetControl]: res.translations[0].translatedText
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (error: any) => {
          this._toasterService.errToastr(
            error?.error?.error?.message || error?.message
          );
        }
      });
  }

  onSubmit(): void {
    this.submitted = true;

    this.brandFormGroup.patchValue({
      name: this.brandFormGroup.get('name')?.value.trim()
    });

    if (this.brandFormGroup.valid) {
      this._spinnerService.addToLoader('brand');

      (this.type === 'add'
        ? this._brandService.addBrand(this.brandFormGroup.value)
        : this._brandService.updateBrand(
          this.brandFormGroup.value,
          this.brandId !== null ? this.brandId : ''
        )
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.type === 'add'
              ? this._toasterService.successToastr(
                this.translateService.instant('BrandAdded')
              )
              : this._toasterService.successToastr(
                this.translateService.instant('BrandUpdated')
              );
            this._spinnerService.removeFromLoader('brand');
            this.dialogRef.close(true);
          },
          error: () => {
            this._spinnerService.removeFromLoader('brand');
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
