<mat-sidenav-container fullscreen>
  <mat-sidenav
    #sidenav
    mode="side"
    class="example-sidenav"
    [ngStyle]="{ 'width.px': sidenavWidth }"
    opened="true">
    <bazaarna-sidebar></bazaarna-sidebar>
  </mat-sidenav>
  <div class="example-sidenav-content">
    <div class="top_header">
      <div class="toggle" (click)="sidenav.toggle()">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="top_header_ryt">
        <div
          class="name_initials pointer"
          mat-button
          [matMenuTriggerFor]="menu">
          <span class="name_center" *ngIf="profileDetails">
            {{ fetchUserName(profileDetails.name) | uppercase }}
          </span>
        </div>

        <mat-menu
          #menu="matMenu"
          class="dropbox"
          xPosition="before"
          yPosition="below">
          <ul class="hd_menu">
            <li routerLink="/profile">
              <figure>
                <img [src]="assetsConst.profileSvg" alt="profile" />
              </figure>
              {{ 'MyProfile' | translate }}
            </li>
            <li routerLink="/profile/change-password">
              <figure>
                <img [src]="assetsConst.changePasswordSvg" alt="profile" />
              </figure>
              {{ 'ChangePassword' | translate }}
            </li>
            <li (click)="logout()">
              <figure>
                <img [src]="assetsConst.logoutSvg" alt="profile" />
              </figure>
              {{ 'Logout' | translate }}
            </li>
          </ul>
        </mat-menu>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <div class="top_header_ryt end_pop">
    <figure mat-icon-button [matMenuTriggerFor]="menu2">
      <img [src]="assetsConst.order" />
    </figure>
    <mat-menu class="end_pop_drop" #menu2="matMenu">
      <div class="not_header">
        <h4 style="text-align: center">{{ 'OrderDetails' | translate }}</h4>
      </div>
      <ng-container
        *ngIf="notificationWidget && notificationWidget.length; else noData">
        <ul *ngFor="let item of notificationWidget" class="hd_menu">
          <li style="cursor: pointer" routerLink="/manage-order/list/0/1">
            <div class="ntf_dt">
              <p>{{ 'orderTempl' | translate }}</p>
              <div class="id_dt">
                <span class="nt_typ"
                  >{{ 'OrderID' | translate }}: {{ item.orderId }}</span
                >
                <span class="dt_ne">{{ item.createdAt | date: 'short' }}</span>
              </div>
            </div>
          </li>
        </ul>
      </ng-container>
      <ng-template #noData>
        <bazaarna-nodatafound></bazaarna-nodatafound>
      </ng-template>
    </mat-menu>
  </div>
</mat-sidenav-container>
