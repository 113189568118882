import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'bazaarna-revert-review',
  templateUrl: './revert-review.component.html',
  styleUrls: ['./revert-review.component.scss']
})
export class RevertReviewComponent {
  constructor(private dialogRef: MatDialogRef<RevertReviewComponent>) {}
  closeDialog(): void {
    // Write your stuff here
    this.dialogRef.close(); // <- Closes the dialog
  }
}
