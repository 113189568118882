<section class="login_wrp store_cat">
  <div class="login_flx">
    <div class="sec_head">
      <h1>{{ 'AddNotification' | translate }}</h1>
      <button type="button" (click)="btn_close()" class="btn btn_primary cls_btn">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="login_frm form_group">
      <form class="add_btn">
        <div class="form_grup">
          <mat-label>Notification Type </mat-label>
          <mat-form-field appearance="fill">
            <mat-select placeholder="Select User" class="form_control">
              <mat-option> Broadcast </mat-option>
              <mat-option> Activity</mat-option>
              <mat-option>Content</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form_grup">
          <mat-label>{{ 'NotificationSend' | translate }} </mat-label>
          <mat-form-field appearance="fill">
            <mat-select placeholder="Select User" class="form_control">
              <mat-option> All </mat-option>
              <mat-option> User</mat-option>
              <mat-option>Subadmin</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form_grup">
          <mat-label>{{ 'NotificationTitle' | translate }} </mat-label>

          <mat-form-field appearance="fill">
            <input
              matInput
              [placeholder]="'Title' | translate"
              class="form_control" />
          </mat-form-field>
        </div>
        <div class="form_grup w-100">
          <mat-label>{{ 'NotificationMessage' | translate }} </mat-label>

          <mat-form-field class="example-full-width" appearance="fill">
            <textarea
              rows="3"
              cols="20"
              class="form_control"
              matInput
              [placeholder]="'Message' | translate"></textarea>
          </mat-form-field>
        </div>

        <div class="submit">
          <button type="button" class="btnn btn_primary w_100">
            {{ 'Submit' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
