<div>
  <div class="tables geo_tbl">
    <mat-table #table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="#">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ID'| translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.storeId }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'OutletAddress' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          {{ element.address | titlecase }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="assign">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Assign' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          <button type="button"
            class="btn btn-primary"
            (click)="assignStore(element._id)"
            [ngClass]="{
              'not-selected': !element.isOnline,
              'pre-selected': element.isOnline
            }">
            {{ 'Assign' | translate }}
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
