import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as tz from 'dayjs/plugin/timezone';
import { EncryptionService } from 'src/app/services/encryption.service';

dayjs.extend(utc);
dayjs.extend(tz);
@Injectable()
export class SetInterceptorService implements HttpInterceptor {
  constructor(private _encryptionSerivce: EncryptionService) {
    // Todo
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const clonedReq = this.handleRequest(req);
    return next.handle(clonedReq);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  handleRequest(req: HttpRequest<unknown>) {
    const storageKey = localStorage.getItem(`${environment['storageKey']}`)
      ? localStorage.getItem(`${environment['storageKey']}`)
      : localStorage.getItem(`${environment['setUpToken']}`);

    const token: string | null = storageKey ? storageKey : null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let authReq: any;
    const headers = {
      'Content-Type': 'application/json',
      authorization: token ? `Bearer ${token}` : '',
      timeZone: dayjs.tz.guess(),
      appId: environment.appId,
      date: dayjs().valueOf().toString()
    };

    // eslint-disable-next-line prefer-const
    authReq = req.clone({
      headers: new HttpHeaders({
        hash: this._encryptionSerivce.encryptHeaders(headers),
        deviceType: 'web'
      })
    });

    if (authReq.url.includes('?')) {
      const encryptQuery = (key: string): string => {
        let url = JSON.parse(JSON.stringify(authReq[key]));
        url = url.split('?');

        url[1] = `hash=${this._encryptionSerivce.encryptHeaders(
          url[1],
          'query'
        )}`;
        url = url.join('?');
        return url;
      };

      authReq.url = encryptQuery('url');
      authReq.urlWithParams = encryptQuery('urlWithParams');
    }

    if (
      req.method.toLowerCase() === 'post' ||
      req.method.toLowerCase() === 'put' ||
      req.method.toLowerCase() === 'delete'
    ) {
      if (
        req.url.includes('merchant/upload') ||
        req.url.includes('translate')
      ) {
        // Do nothing
        if (req.url.includes('translate')) {
          authReq = req.clone({
            headers: new HttpHeaders({
              'content-type': 'application/x-www-form-urlencoded',
              'Accept-Encoding': 'application/gzip',
              'X-RapidAPI-Key':
                '543635f740msh44cbe1162a3944fp132073jsnde9d3dbac853',
              'X-RapidAPI-Host': 'google-translate1.p.rapidapi.com'
            })
          });
        }
      } else {
        authReq['body'] = {
          hash: this._encryptionSerivce.encryptionPayload(
            JSON.stringify(authReq.body)
          )
        };
      }
    }
    return authReq;
  }
}
