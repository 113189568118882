import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'bazaarna-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  userArray = [
    {
      name: 'Customer',
      value: 'customer'
    },
    {
      name: 'Hospital',
      value: 'hospital'
    }
  ];

  constructor(public dialog: MatDialog) {}

  btn_close(): void {
    this.dialog.closeAll();
  }
}
