<div class="pop_mn">
  <div class="sec_head">
    <div class="sec_lft">
      <h2>{{ 'discountPreview' | translate }}</h2>
    </div>
  </div>

  <div class="invoice_mn">
    <div class="invoice_lft w_100">
      <p>
        <b>{{ 'DiscountType' | translate }} :</b>
        {{ data.discountType | titlecase }}
      </p>
      <p>
        <b>{{ 'saleduration' | translate }} :</b>
        {{ data.startDate | date: 'mediumDate' }} to
        {{ data.endDate | date: 'mediumDate' }}
      </p>
      <p *ngIf="!data.isSelectedAll">
        <b>{{ 'saleonOrducts' | translate }}: </b
        >{{ 'slectedBrands' | translate }}
      </p>
      <p *ngIf="data.isSelectedAll">
        <b>{{ 'saleonOrducts' | translate }}: </b
        >{{ 'OnAllProducts' | translate }}
      </p>

      <p>
        <b>{{ 'Discount' | translate }}: </b> {{ discountAmount(data) }}
      </p>
    </div>
  </div>
  <div class="submit">
    <button type="button" (click)="submit()" class="btnn btn_primary">
      {{ 'Submit' | translate }}
    </button>
    <button (click)="close()" type="button" class="btnn btn_primary">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</div>
