import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginBody } from '../models/loginBody';
import { AdminUser } from '../models/adminUser';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { forgotPassword } from '../models/forgotPassword';
import { messageResponse } from '../models/messageResponse';
import { logoutResponse } from '../models/logoutResponse';
import { changePassword } from '../models/changePassword';
import Swal from 'sweetalert2';
import { resetPassword } from '../models/resetPassword';
import { signUpBody } from '../models/signUpBody';
import { verifyOTPBody } from '../models/verifyOTPBody';
import { setUpProfile } from '../models/setUpProfileBody';
import { VerifyEmailOrPhone } from '../models/VerifyEmailOrPhone';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  profileDetails: BehaviorSubject<AdminUser | null> =
    new BehaviorSubject<AdminUser | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fcmToken = new BehaviorSubject<any>(null);

  docsData = {
    documentName: '',
    isFrontImageRequired: false,
    isBackImageRequired: false,
    isExpiryRequired: false,
    frontImage: '',
    backImage: '',
    expiryDate: ''
  };

  constructor(private readonly http: HttpClient) {}

  isAuthenticated(): boolean {
    if (
      localStorage.getItem(environment.storageKey) &&
      localStorage.getItem(environment.storageKey) !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  loginMerchant(payload: LoginBody): Observable<AdminUser> {
    return this.http
      .post<{
        data: AdminUser;
      }>(`${environment.base_url}merchant/login`, payload)
      .pipe(map(res => res.data));
  }

  signUpMerchant(payload: signUpBody): Observable<messageResponse> {
    return this.http.post<messageResponse>(
      `${environment.base_url}merchant/register`,
      payload
    );
  }

  registerProfile(payload: signUpBody): Observable<AdminUser> {
    return this.http
      .post<{
        data: AdminUser;
      }>(`${environment.base_url}merchant/register`, payload)
      .pipe(map(res => res.data));
  }

  verifyEmailOrPhone(payload: VerifyEmailOrPhone): Observable<AdminUser> {
    return this.http
      .post<{
        data: AdminUser;
      }>(`${environment.base_url}merchant/validateAccount`, payload)
      .pipe(map(res => res.data));
  }

  verifyMailOrPhoneOtp(payload: VerifyEmailOrPhone): Observable<AdminUser> {
    return this.http
      .post<{
        data: AdminUser;
      }>(`${environment.base_url}merchant/verifyValidate`, payload)
      .pipe(map(res => res.data));
  }

  resendOtp(payload: VerifyEmailOrPhone): Observable<AdminUser> {
    return this.http
      .post<{
        data: AdminUser;
      }>(`${environment.base_url}merchant/verifyValidate`, payload)
      .pipe(map(res => res.data));
  }

  verifyOTP(payload: verifyOTPBody): Observable<AdminUser> {
    return this.http
      .post<{
        data: AdminUser;
      }>(`${environment.base_url}merchant/verifyotp`, payload)
      .pipe(map(res => res.data));
  }

  forgotPassword(payload: forgotPassword): Observable<messageResponse> {
    return this.http
      .post<{
        data: messageResponse;
      }>(`${environment.base_url}merchant/forgotPassword`, payload)
      .pipe(map(res => res.data));
  }

  changePassword(payload: changePassword): Observable<messageResponse> {
    return this.http
      .post<{
        data: messageResponse;
      }>(`${environment.base_url}merchant/changepassword`, payload)
      .pipe(map(res => res.data));
  }

  resetPassword(payload: resetPassword): Observable<messageResponse> {
    return this.http
      .post<{
        data: messageResponse;
      }>(`${environment.base_url}merchant/resetPassword`, payload)
      .pipe(map(res => res.data));
  }

  getMerchantProfile(): Observable<AdminUser> {
    return this.http
      .get<{ data: AdminUser }>(`${environment.base_url}merchant/getProfile`)
      .pipe(map(res => res.data));
  }

  setUpMerchant(payload: setUpProfile): Observable<LoginBody> {
    return this.http
      .put<{
        data: LoginBody;
      }>(`${environment.base_url}merchant/updateProfile`, payload)
      .pipe(map(res => res.data));
  }

  getMerchantDocsFromAdmin(): Observable<AdminUser> {
    return this.http
      .get<{
        data: AdminUser;
      }>(`${environment.base_url}merchant/getMerchantDocsFromAdmin`)
      .pipe(map(res => res.data));
  }

  updateProfile(payload: AdminUser): Observable<AdminUser> {
    return this.http
      .put<{
        data: AdminUser;
      }>(`${environment.base_url}merchant/updateProfile`, payload)
      .pipe(map(res => res.data));
  }

  logout(payload: string): Observable<logoutResponse> {
    return this.http.post<logoutResponse>(
      `${environment.base_url}/merchant/logout`,
      payload
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logoutAlert(): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise((resolve: any) => {
      Swal.fire({
        title: 'Are You Sure?',
        text: 'You will be logged out!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonColor: '#3085D6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Logout'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }).then((result: any) => {
        return resolve(result);
      });
    });
  }
}
