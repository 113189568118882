import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { assetsConst } from './core/const/assets.const';
import { environment } from 'src/environments/environment';
import { SwPush, SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { AuthService } from './services/auth.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'bazaarna-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'daisy';
  assetsConst = assetsConst;
  message: any = null;

  constructor(
    translate: TranslateService,
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    private _authService: AuthService
  ) {
    if (!localStorage.getItem(environment.storageKey)) {
      localStorage.clear();
      // Redirect to login page or handle authentication flow
    }
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit(): void {
    this.requestPermission();
    this.listen();
    this.checkForUpdates();
  }

  checkForUpdates(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(() => {
          this.reloadWithIcon();
        });

      this.swUpdate.unrecoverable.subscribe(() => {
        this.reloadWithIcon();
      });
    }
  }

  private reloadWithIcon(): void {
    window.location.reload();
  }

  requestPermission(): void {
    const messaging = getMessaging();
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        getToken(messaging, { vapidKey: environment.firebase.vapidKey })
          .then(currentToken => {
            if (currentToken) {              
              this._authService.fcmToken.next(currentToken);
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          })
          .catch(err => {
            console.log('An error occurred while retrieving token. ', err);
          });
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  }
  

  listen(): void {
    const messaging = getMessaging();
    onMessage(messaging, payload => {
      console.log('Message received. ', payload);
      this.message = payload;
      if (payload.notification) {
        this.showCustomNotification(payload.notification);
      }
    });
  }

  private showCustomNotification(notification: any): void {
    const notificationOptions = {
      body: notification.body || '',
      icon: assetsConst.order || '',
      data: notification
    };

    // Check for service worker registration
    navigator.serviceWorker
      .getRegistration()
      .then(registration => {
        if (registration) {
          registration.showNotification(notification.title, notificationOptions);
        } else {
          console.error('Service worker registration not found.');
        }
      })
      .catch(err => console.error('Service worker error', err));
  }

  subscribeToNotifications(): void {
    this.swPush
      .requestSubscription({
        serverPublicKey: environment.firebase.vapidKey
      })
      .then(sub => {
        // Todo
      })
      .catch(err => console.error('Could not subscribe to notifications', err));
  }
}