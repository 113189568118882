export const assetsConst = {
  loaderGif: 'assets/images/0998.gif',
  addSvg: 'assets/images/add.svg',
  camera: 'assets/images/camera.png',
  cube: 'assets/images/cubes.png',
  dash: 'assets/images/dash.svg',
  discount: 'assets/images/discount.svg',
  driver: 'assets/images/driver.svg',
  logo: 'assets/images/logo.svg',
  logo2: 'assets/images/logo2.svg',
  logoutSvg: 'assets/images/logout.svg',
  user: 'assets/images/user.png',
  logoPNG: 'assets/images/logo.png',
  manage_order: 'assets/images/manage_order.svg',
  marUp: 'assets/images/markup_price.svg',
  newUser: 'assets/images/new_users.svg',
  noDataFound: 'assets/images/not_found.svg',
  order2: 'assets/images/ord2.svg',
  order3: 'assets/images/ord3.svg',
  order4: 'assets/images/ord4.svg',
  order5: 'assets/images/ord5.svg',
  order: 'assets/images/order.png',
  changePasswordSvg: 'assets/images/password.svg',
  payment: 'assets/images/payment.svg',
  placeHolder: 'assets/images/placeholder.png',
  qty: 'assets/images/qty.svg',
  reports: 'assets/images/reports.svg',
  star: 'assets/images/star.png',
  totalUser: 'assets/images/total_user.svg',
  profileSvg: 'assets/images/user.svg',
  vehicle: 'assets/images/vehicles.svg',
  // audioSource: 'assets/sounds/notification-sound1.mp3'
  audioSource: 'assets/sounds/work_alert.mp3'
};
