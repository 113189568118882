import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, catchError, takeUntil, tap } from 'rxjs';
import { translationResponse } from 'src/app/models/translateText';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { TranslateTextService } from 'src/app/services/translateText.service';

@Component({
  selector: 'bazaarna-add-vehicle',
  templateUrl: './add-vehicle.component.html'
})
export class AddVehicleComponent implements OnInit {
  submitted = false;
  vehicleFormGroup: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private _spinnerService: SpinnerService,
    private _toasterService: ToasterService,
    private _translateTextService: TranslateTextService,
    private translateService: TranslateService,

    private dialogRef: MatDialogRef<AddVehicleComponent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.vehicleFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      name_ar: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.vehicleFormGroup.patchValue({
      name: this.data?.data?.name,
      name_ar: this.data?.data?.name_ar
    });
  }

  translate(control: string, controlValue: string): void {
    const targetLanguage = control.includes('_ar') ? 'en' : 'ar';
    const sourceLanguage = targetLanguage === 'en' ? 'ar' : 'en';
    const targetControl =
      targetLanguage === 'en' ? control.split('_ar')[0] : `${control}_ar`;

    const body = new URLSearchParams({
      q: controlValue,
      target: targetLanguage,
      source: sourceLanguage
    });

    this._spinnerService.addToLoader('translateText');
    this._translateTextService
      .translateApi(body)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this._spinnerService.removeFromLoader('translateText')),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catchError((error: any) => {
          this._spinnerService.removeFromLoader('translateText');
          throw error;
        })
      )
      .subscribe({
        next: (res: translationResponse) => {
          this._toasterService.successToastr(
            this.translateService.instant('textTranslatedSuccess')
          );
          this.vehicleFormGroup.patchValue({
            [targetControl]: res.translations[0].translatedText
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (error: any) => {
          this._toasterService.errToastr(
            error?.error?.error?.message || error?.message
          );
        }
      });
  }

  onSubmit(): void {
    this.submitted = true;

    this.vehicleFormGroup.patchValue({
      name: this.vehicleFormGroup.get('name')?.value?.trim(),
      name_ar: this.vehicleFormGroup.get('name_ar')?.value?.trim()
    });

    if (this.vehicleFormGroup.valid) {
      this.dialogRef.close(this.vehicleFormGroup.value);
    }
  }
}
