import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Subject, takeUntil } from 'rxjs';
import { DriverService } from 'src/app/services/driver.service';
import { salaryTypeNumber } from 'src/app/core/const/salaryType.const';

@Component({
  selector: 'bazaarna-driver-assign',
  templateUrl: './driver-assign.component.html'
})
export class DriverAssignComponent implements OnDestroy {
  dataSource = [];
  displayedColumns = ['#', 'driverDetail', 'vehicleType', 'action'];
  destroy$: Subject<boolean> = new Subject<boolean>();
  salaryType = salaryTypeNumber;
  constructor(
    private _driverService: DriverService,
    private _spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<DriverAssignComponent>
  ) {
    this.getAvailableDriverList();
  }

  getAvailableDriverList(): void {
    this._spinnerService.addToLoader('driversThroughZone');
    this._driverService
      .getAvailableDriver(this.data)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (res: any) => {
          this._spinnerService.removeFromLoader('driversThroughZone');
          this.dataSource = res?.data?.data[0]?.drivers;
        },
        error: () => {
          this._spinnerService.removeFromLoader('getAvailableDrivers');
        }
      });
  }

  onDriverSelection(id: string): void {
    const body = {
      driverId: id,
      orderId: this.data
    };

    this.dialogRef.close(body);
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
