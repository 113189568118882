<figure>
  <img [src]="data" alt="" />
</figure>

<p>{{ 'UploadImage' | translate }}</p>
<div class="form_group cust_row upld_img_cat">
  <div class="usr_profile">
    <div class="prfl_pic">
      <figure class="prfl_pic">
        <img [src]="image ? image : assetsConst.placeHolder" alt="" />
      </figure>
      <figure class="cmraicon">
        <img [src]="assetsConst.camera" alt="" />
        <input
          accept="image/*"
          type="file"
          class="upld_file_inp"
          (change)="onFileChange($event)" />
      </figure>
    </div>
  </div>
</div>
<div *ngIf="image">
  <h2>
    {{ 'imageLink' | translate }}
  </h2>
</div>
<div class="copy_link" *ngIf="image">
  <p
    [ngClass]="{
      hightlighted: this.hightlighted
    }">
    {{ image }}
  </p>

  <button type="button"
    class="btn btn-primary"
    (click)="highlight()"
    [cdkCopyToClipboard]="image">
    {{ 'copyLink' | translate }}
  </button>
</div>
