<div class="logomain">
  <figure class="st_logo" routerLink="/dashboard">
    <img [src]="assetsConst.logo" alt="logo-w" />
  </figure>
  <figure class="rs_logo" routerLink="/dashboard">
    <img [src]="assetsConst.logo2" alt="logo-w" />
  </figure>
</div>
<mat-nav-list class="sd_bar">
  <div
    *ngFor="let item of sideBar"
    (click)="fetchEvent(item)"
    [ngClass]="{
      grey_class: fadeClass(item)
    }">
    <mat-list-item
      [routerLink]="item.path"
      routerLinkActive="active"
      *ngIf="!item.isSubMenu">
      <figure
        class="sd_cn"
        matTooltip="{{ item.text }}"
        matTooltipPosition="right">
        <img src="{{ item.icon }}" alt="images" />
      </figure>
      <div fxFlex="10"></div>
      <div *ngIf="sidenavWidth > 6" class="sidenav-item">
        <h5 class="lead">{{ item.text }}</h5>
      </div>
    </mat-list-item>
    <mat-accordion *ngIf="item.isSubMenu">
      <mat-expansion-panel class="drop_lst_nv">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-list-item>
              <figure
                class="sd_cn"
                matTooltip="{{ item.text }}"
                matTooltipPosition="right">
                <img src="{{ item.icon }}" alt="images" />
              </figure>
              <div fxFlex="10"></div>
              <div *ngIf="sidenavWidth > 6" class="sidenav-item">
                <h5 class="lead">{{ item.text }}</h5>
              </div>
            </mat-list-item>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list-item
          *ngFor="let sub of item.subMenuItems"
          [routerLink]="sub.path">
          <figure
            class="sd_cn"
            matTooltip="{{ item.text }}"
            matTooltipPosition="right">
            <img src="{{ sub.icon }}" alt="images" />
          </figure>
          <div *ngIf="sidenavWidth > 6" class="sidenav-item">
            <h5 class="lead">{{ sub.text }}</h5>
          </div>
        </mat-list-item>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</mat-nav-list>
