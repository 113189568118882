<div class="sec_head">
  <h1>{{ 'justVerify' | translate }}</h1>
  <p>
    {{ 'enterCode' | translate }}
    {{ data.type === 'phone' ? data.countryCode + ' ' + data.key : data.key }}
  </p>
</div>
<div class="mat_cnt_wrp">
  <form>
    <div class="frm_grp">
      <div class="frm_cnt w_100 mt_80">
        <ng-otp-input
          (onInputChange)="onOtpChange($event)"
          [config]="{ length: 4 }"></ng-otp-input>
      </div>
    </div>
    <div class="submit_btn">
      <button class="btnn btn_primary w_100" type="submit" (click)="onSubmit()">
        {{ 'Verify' | translate }}
      </button>
    </div>
  </form>
</div>
<div>
  <p *ngIf="timer === 0" class="text_center mt_70">
    {{ 'codeReceivedNot' | translate }}
    <a class="link" (click)="resendOtp()">{{ 'resesndCode' | translate }}</a>
  </p>
  <p class="vrify_txt">
    {{ 'verifyCodeExpire' | translate }}<span *ngIf="timer < 10">{{ 0 }}</span
    >{{ timer }}
  </p>
</div>
