import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NotificationComponent } from './notification/notification.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { ReasonPopupComponent } from './reason-popup/reason-popup.component';
import { RevertReviewComponent } from './revert-review/revert-review.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { RejectionReasonComponent } from './rejection-reason/rejection-reason.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AcceptPopupComponent } from './accept-popup/accept-popup.component';
import { PreviewDataComponent } from './preview-data/preview-data.component';
import { AddVehicleComponent } from './add-vehicle/add-vehicle.component';
import { DriverAssignComponent } from './driver-assign/driver-assign.component';
import { AfterLoginShared } from '../shared/after-login.shared.module';
import { SubstituteOrderComponent } from './substitute-order/substitute-order.component';
import { AddBrandComponent } from './add-brand/add-brand.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OrderInvoiceComponent } from './order-invoice/order-invoice.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '../shared/pipes/pipes.module';
import { ChooseDriverComponent } from './choose-driver/choose-driver.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { AddAttributeComponent } from './add-attribute/add-attribute.component';
import { PaginatorModule } from '../shared/components/paginator/paginator.module';
import { SearchModule } from '../shared/components/search/search.module';

@NgModule({
  declarations: [
    NotificationComponent,
    ImageViewerComponent,
    ReasonPopupComponent,
    RevertReviewComponent,
    RejectionReasonComponent,
    AddVehicleComponent,
    AcceptPopupComponent,
    PreviewDataComponent,
    DriverAssignComponent,
    SubstituteOrderComponent,
    AddBrandComponent,
    OrderInvoiceComponent,
    ChooseDriverComponent,
    VerifyOtpComponent,
    AddAttributeComponent
  ],
  exports: [OrderInvoiceComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,
    TranslateModule,
    MatRadioModule,
    MatTabsModule,
    MatTableModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    AfterLoginShared,
    MatSlideToggleModule,
    MatTooltipModule,
    PipesModule,
    ClipboardModule,
    NgOtpInputModule,
    PaginatorModule,
    SearchModule
  ]
})
export class ModalsModule {}
