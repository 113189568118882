import { Pipe, PipeTransform } from '@angular/core';
import { currencyConst } from 'src/app/core/const/currency.const';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  /**
   * Custom pipe is created because Omani Rial (OMR) was not supported by
   * Angular currency pipe
   * @param value number to which pipe is being applied
   * @returns string which is number as prefix and OMR symbol as suffix
   */

  transform(value: number): string {
    return currencyConst.omaniRial + value.toFixed(2);
  }
}
