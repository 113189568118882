<div class="container-fluid">
  <div class="tabel-page nw_pop">
    <div class="modal_title">
      <h1>
        {{
          type === 'add' ? ('AddBrand' | translate) : ('EditBrand' | translate)
        }}
      </h1>
    </div>

    <form [formGroup]="brandFormGroup" (ngSubmit)="onSubmit()">
      <div class="tables">
        <div class="form_feilds">
          <div class="form_grup w-100 btn_rght pdct_btn">
            <mat-label>
              {{ 'BrandName' | translate }}
              <span class="astrick"></span
            ></mat-label>
            <mat-form-field appearance="fill">
              <input
                matInput
                placeholder="{{ 'BrandName' | translate }}"
                class="form_control"
                formControlName="name" />
            </mat-form-field>
            <button type="button"
              *ngIf="brandFormGroup.get('name')?.value"
              class="btn btn-primary"
              (click)="translate('name', brandFormGroup.get('name')?.value)">
              {{ 'translate' | translate }}
            </button>
            <mat-error
              *ngIf="
                submitted && brandFormGroup.get('name')?.hasError('required')
              ">
              {{ 'BrandName' | translate }}
              {{ 'IsRequired' | translate }}
            </mat-error>
          </div>

          <div class="form_grup w-100 btn_rght pdct_btn">
            <mat-label>
              {{ 'BrandName_ar' | translate }}
              <span class="astrick"></span
            ></mat-label>
            <mat-form-field appearance="fill">
              <input
                matInput
                placeholder="{{ 'BrandName_ar' | translate }}"
                class="form_control"
                formControlName="name_ar" />
            </mat-form-field>
            <button type="button"
              *ngIf="brandFormGroup.get('name_ar')?.value"
              class="btn btn-primary"
              (click)="
                translate('name_ar', brandFormGroup.get('name_ar')?.value)
              ">
              {{ 'translate' | translate }}
            </button>
            <mat-error
              *ngIf="
                submitted && brandFormGroup.get('name_ar')?.hasError('required')
              ">
              {{ 'BrandName_ar' | translate }}
              {{ 'IsRequired' | translate }}
            </mat-error>
          </div>

          <div class="form_grup"></div>
          <div class="filter_lft">
            <button class="btn btn-primary" type="submit">
              {{
                type === 'add' ? ('Save' | translate) : ('Update' | translate)
              }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
